<template>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
        <v-container grid-list-xl>
            <v-layout wrap>
                <v-flex xs12>
                    <v-switch :label="$t('Habilitado')" v-model="item.enable" class="my-0 py-0" />
                    <v-switch :label="$t('Em Uso')" v-model="item.inUse" class="my-0 py-0" />
                </v-flex>
                <v-flex xs6 mb-6>
                    <v-text-field :label="$t('Placa')" v-model="item.plate" v-mask="'AAA#X##'" :rules="[rules.brazilianPlate]" />
                </v-flex>
                <v-flex xs6 mb-6>
                    <v-text-field :label="$t('Número de serie')" v-model="item.serialNumber" />
                </v-flex>

                <v-flex xs12>
                    <v-autocomplete :label="$t('Loja')" v-model="item.store" :items="stores" item-value="id" item-text="name" />
                </v-flex>
                <v-flex xs12 pb-0>
                    <v-autocomplete :label="$t('Locadora')" v-model="item.rental" :items="rentals" item-value="id" item-text="name" />
                </v-flex>
                <v-flex xs12 py-0>
                    <v-autocomplete :label="$t('Carregador')" v-model="item.charger" :items="chargers" item-value="id" item-text="name" />
                </v-flex>
                <v-flex xs12 mb-6 pt-0>
                    <v-autocomplete :label="$t('Baterias')" v-model="item.batteries" :items="batteries" item-value="id" item-text="name" deletable-chips multiple small-chips />
                </v-flex>

                <v-flex xs6>
                    <v-autocomplete v-model="item.type" :items="vehiclesTypes" :label="$t('Categoria')" :rules="[rules.required]" />
                    <v-autocomplete v-model="item.brand" :items="brands" :label="$t('Marca')" :rules="[rules.required]" :disabled="item.type == null" />
                    <v-text-field :label="$t('Modelo')" v-model="item.model" :rules="[rules.required]" />
                </v-flex>
                <v-flex xs6>
                    <v-subheader> {{ $t("Ano de fabricação") }} </v-subheader>
                    <v-slider class="mx-4 mt-8" thumb-size="32" thumb-label="always" v-model="item.yearOfManufacture" :min="startYear" :max="endYear" />
                </v-flex>
                <v-flex xs12 sm6>
                    <v-text-field :label="$t('Chassi')" v-model="item.chassis" v-mask="rules.chassisMask" :rules="[rules.required, rules.chassis]" />
                </v-flex>
                <v-flex xs12 sm6>
                    <v-text-field :label="$t('Número do motor')" v-model="item.engineNumber" v-mask="rules.motorMask" :rules="[rules.required, rules.motor]" />
                </v-flex>
                <v-flex xs12 md12 sm12>
                    <v-btn :loading="lock" :disabled="!valid" color="success" @click="validate">Salvar</v-btn>
                </v-flex>
            </v-layout>
        </v-container>
    </v-form>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";
import brandsMotorcycle from "@/assets/json/brandsMotorcycle.json";
import brandsCar from "@/assets/json/brandsCar.json";
import vehiclesTypes from "@/assets/json/vehiclesTypes.json";

export default {
    directives: { mask },

    props: {
        itemToEdit: {
            type: Object,
        },
        lock: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            rules,
            valid: true,
            brandsMotorcycle,
            brandsCar,
            vehiclesTypes,
            itemClean: {
                type: null,
                plate: "",
                brand: "",
                model: "",
                yearOfManufacture: 2021,
                chassis: "",
                engineNumber: "",
                enable: true,
            },
            item: { ...this.itemClean },
            batteries: null,
            chargers: null,
            rentals: null,
            stores: null,
        };
    },

    computed: {
        startYear() {
            return new Date().getFullYear() - 20;
        },
        endYear() {
            return new Date().getFullYear() + 1;
        },
        brands() {
            if (this.item.type == null) {
                return [];
            } else if (this.item.type == "Moto") {
                return this.brandsMotorcycle;
            } else {
                return this.brandsCar;
            }
        },
    },

    watch: {
        itemToEdit: {
            immediate: true,
            handler(newVal) {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }

                this.$nextTick(() => {
                    if (newVal == null) {
                        this.item = { ...this.itemClean };
                    } else {
                        this.item = { ...newVal };
                    }
                });
            },
        },
    },

    methods: {
        getBatteries() {
            this.$http
                .get(`batteries`)
                .then((result) => {
                    if (result) {
                        this.batteries = result;
                    }
                })
                .catch(() => {
                    this.batteries = null;
                });
        },
        getChargers() {
            this.$http
                .get(`chargers`)
                .then((result) => {
                    if (result) {
                        this.chargers = result;
                    }
                })
                .catch(() => {
                    this.chargers = null;
                });
        },
        getRentals() {
            this.$http
                .get(`rentals`)
                .then((result) => {
                    if (result) {
                        this.rentals = result;
                    }
                })
                .catch(() => {
                    this.rentals = null;
                });
        },
        getStores() {
            this.$http
                .get('stores?enable=true')
                .then((result) => {
                    if (result) {
                        this.stores = result;
                    }
                })
                .catch(() => {
                    this.stores = null;
                });
        },
        validate() {
            if (this.$refs.form.validate()) {
                this.$emit("save", this.item);
            }
        },
    },

    mounted() {
        this.getBatteries();
        this.getChargers();
        this.getRentals();
        this.getStores();
        if (this.$refs.form) {
            this.$refs.form.reset();
        }
    },
};
</script>